// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reach-location_flicker__pRQ3m {
	display: block;
	background-color: red;
	border-radius: 50%;
	width: 20px;
	height: 20px;
	aspect-ratio: 1;
	box-shadow: 0 0 5px red;
	border: 2px solid red;
	animation: reach-location_blink__cPjof 2s infinite;
}

@keyframes reach-location_blink__cPjof {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	100% {
		opacity: 1;
	}
}
`, "",{"version":3,"sources":["webpack://./src/components/ui/homepage/reachLocation/reach-location.module.css"],"names":[],"mappings":"AAAA;CACC,cAAc;CACd,qBAAqB;CACrB,kBAAkB;CAClB,WAAW;CACX,YAAY;CACZ,eAAe;CACf,uBAAuB;CACvB,qBAAqB;CACrB,kDAA4B;AAC7B;;AAEA;CACC;EACC,UAAU;CACX;CACA;EACC,YAAY;CACb;CACA;EACC,UAAU;CACX;AACD","sourcesContent":[".flicker {\n\tdisplay: block;\n\tbackground-color: red;\n\tborder-radius: 50%;\n\twidth: 20px;\n\theight: 20px;\n\taspect-ratio: 1;\n\tbox-shadow: 0 0 5px red;\n\tborder: 2px solid red;\n\tanimation: blink 2s infinite;\n}\n\n@keyframes blink {\n\t0% {\n\t\topacity: 1;\n\t}\n\t50% {\n\t\topacity: 0.5;\n\t}\n\t100% {\n\t\topacity: 1;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flicker": `reach-location_flicker__pRQ3m`,
	"blink": `reach-location_blink__cPjof`
};
export default ___CSS_LOADER_EXPORT___;
