import '../components/ui/background.css'
import PhysicalGame from '../components/ui/homepage/physicalGame'
const Homepage = () => {


	return (
		<>
			<div className='h-full'>
			<PhysicalGame />
			</div>
		</>
	)
}

export default Homepage